/*
 * External dependencies
 */
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
const SocialShare = ( { frontmatter } ) => (
    <div className="social-share">
        <span className="social-share-label">
            Share this post:
        </span>
        <ul>
            <li className="twitter">
                <a href={`https://twitter.com/intent/tweet/?text=${frontmatter.title}&url=https://www.tomhirst.com${frontmatter.url}&via=tom_hirst`} target="_blank">
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                </a>
            </li>
            <li className="linkedin">
                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.tomhirst.com${frontmatter.url}&title=${frontmatter.title}&source=${frontmatter.title}`} target="_blank">
                    <FontAwesomeIcon icon={['fab', 'linkedin']} />
                </a>
            </li>
            <li className="facebook">
                <a href={`https://www.facebook.com/sharer/sharer.php?u=https://www.tomhirst.com${frontmatter.url}`} target="_blank">
                    <FontAwesomeIcon icon={['fab', 'facebook']} />
                </a>
            </li>
        </ul>
    </div>
);

export default SocialShare;
