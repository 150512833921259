/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import parser from 'html-react-parser';
import { slugify } from 'underscore.string';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import Image from '../components/image';
import ShapedImage from '../components/shaped-image';
import SocialShare from '../components/social-share';
import InternalLink from '../components/internal-link';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import nftsOgImage from '../images/nfts-og-image.png';

const BlogPostTemplate = ( props ) => {
    const {
        location,
        data,
    } = props;

    const {
        markdownRemark,
    } = data;

    const {
        frontmatter,
        excerpt,
        html,
    } = markdownRemark;

    const {
        date,
        title,
        featured_image: featuredImage,
        tags,
        categories,
        hide_featured_image: hideFeaturedImage,
        custom_excerpt: customExcerpt,
    } = frontmatter;

    const SeoImageUrl = title == 'The Ultimate Guide To Getting Started With NFTs' ? `https://www.tomhirst.com${nftsOgImage}` : `https://www.tomhirst.com${featuredImage.childImageSharp.fluid.src}`;

    return (
        <Layout location={ location } noCta>
            <SEO
                title={ title }
                description={ customExcerpt || excerpt }
                imageAlt={ title }
                imageUrl={ SeoImageUrl }
            />
            <Section narrow>
                <article className="blog-post-single">
                    <header>
                        <h1>{ title }</h1>
                        <time>
                            { date }
                        </time>
                        { featuredImage && ! hideFeaturedImage && (
                            <div className="blog-post-image"><Image
                                name={ featuredImage.childImageSharp.fluid.originalName }
                                alt={ title }
                            />
                            </div>
                        ) }
                    </header>
                    <div>
                        { parser( html ) }
                        { categories && categories.length > 0 && (
                            <div className="categories term-list">
                                <span>Posted in:</span>
                                <ul>
                                    { categories.map( category => (
                                        <li key={ category }>
                                            <InternalLink to={ `/category/${slugify( category )}/` }>
                                                { category }
                                            </InternalLink>
                                        </li>
                                    ) ) }
                                </ul>
                            </div>
                        ) }
                        { tags && tags.length > 0 && (
                            <div className="tags term-list">
                                <span>Tagged:</span>
                                <ul>
                                    { tags.map( tag => (
                                        <li key={ tag }>
                                            <InternalLink to={ `/tag/${slugify( tag )}/` }>
                                                { tag }
                                            </InternalLink>
                                        </li>
                                    ) ) }
                                </ul>
                            </div>
                        ) }
                        <SocialShare frontmatter={ frontmatter } />
                    </div>
                    <footer id="hire-me">
                        <div className="row align-items-md-center">
                            <div className="col-md-4">
                                <ShapedImage
                                    name="tom-hirst-headshot.png"
                                    alt="WordPress Consultant Tom Hirst"
                                    grayscale
                                />
                            </div>
                            <div className="col-md-8">
                                <h3>The author</h3>
                                <p>
                                    This article was written by Tom Hirst. A long-time remote
                                    working freelance web developer and educator who loves helping others
                                    get ahead through his experiences.
                                </p>
                                <ul className="icon-list">
                                    <li>
                                        <a href="https://twitter.com/tom_hirst/">
                                            <FontAwesomeIcon icon={['fab', 'twitter']} />
                                            Follow on Twitter
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://instagram.com/tom_hirst/">
                                            <FontAwesomeIcon icon={['fab', 'instagram']} />
                                            Follow on Instagram
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/in/tomhirst/">
                                            <FontAwesomeIcon icon={['fab', 'linkedin']} />
                                            Connect on LinkedIn
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </footer>
                </article>
            </Section>
        </Layout>
    );
};

BlogPostTemplate.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
    data: PropTypes.objectOf( PropTypes.any ),
};

BlogPostTemplate.defaultProps = {
    location: {},
    data: {},
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            html
            excerpt(pruneLength: 160)
            frontmatter {
                title
                date(formatString: "Do MMMM YYYY")
                featured_image {
                    childImageSharp {
                        fluid {
                            base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
                url
                categories
                tags
                hide_featured_image
                mentorship_og_image
                custom_excerpt
            }
        }
    }
`;
